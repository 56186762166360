<template>
  <div
    class="conversation-item-container"
    :class="{
      choose: conversation.consultationOrderNo === custom.consultationOrderNo,
    }"
    @click="selectConversation"
  >
    <!---->
    <el-badge value="+1" class="badge-item" v-show="showUnreadCount">
    </el-badge>
    <div class="warp">
      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text-ellipsis">
              <span>{{ conversation.patientName }} </span>
              <span>{{ consultationType[conversation.consultationType] }}</span>
            </div>
          </div>
          <div class="count-out">
            <a href="javascript:;" :class="conversation.orderStatus">{{
              orderStatus(conversation.orderStatus)
            }}</a>
          </div>
        </div>
        <div class="row-2">
          <div class="date">
            {{ date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isToday, getDate, getTime } from '@/utils/date';
import debounce from 'lodash/debounce';
import data1 from '@/constants/consultationTypes';
const consultationType = Object.keys(data1).reduce((acc, key) => {
  acc[data1[key].consultationTypes] = data1[key].title;
  return acc;
}, {});

export default {
  name: 'conversation-item',
  props: ['conversation'],
  components: {
    // GroupItem,
    // avatar,
  },
  data() {
    return {
      consultationType,
      popoverVisible: false,
      hasMessageAtMe: false,
      kaiyao: [],

      currentOrder: {},
      diaName: '',
      diaOrderStatus: '',
    };
  },
  computed: {
    showUnreadCount() {
      // console.log(
      //     this.$store.getters.hidden,
      //     this.conversation.unreadCount,
      //     '未读'
      // );
      // if (this.$store.getters.hidden) {
      //     return this.conversation.unreadCount > 0;
      // }
      // 是否显示未读计数。当前会话和未读计数为0的会话，不显示。
      return (
        this.conversation.consultationOrderNo !=
          this.custom.consultationOrderNo && this.conversation.unreadCount > 0
      );
    },
    date() {
      if (
        !this.conversation.lastMessage ||
        !this.conversation.lastMessage.lastTime
      ) {
        return '';
      }
      const date = new Date(this.conversation.lastMessage.lastTime * 1000);
      if (isToday(date)) {
        return getTime(date);
      }
      return getDate(date);
    },
    avatar: function () {
      switch (this.conversation.type) {
        case 'GROUP':
          return this.conversation.groupProfile.avatar;
        case 'C2C':
          return this.conversation.userProfile.avatar;
        default:
          return '';
      }
    },
    conversationName: function () {
      if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
        return (
          this.conversation.userProfile.nick ||
          this.conversation.userProfile.userID
        );
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_GROUP) {
        return this.conversation.groupProfile.name;
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_SYSTEM) {
        return '系统通知';
      }
      return '';
    },
    showGrayBadge() {
      if (this.conversation.type !== this.TIM.TYPES.CONV_GROUP) {
        return false;
      }
      return (
        this.conversation.groupProfile.selfInfo.messageRemindType ===
        'AcceptNotNotify'
      );
    },
    messageForShow() {
      if (this.conversation.lastMessage.isRevoked) {
        if (
          this.conversation.lastMessage.fromAccount ===
          this.currentUserProfile.userID
        ) {
          return '你撤回了一条消息';
        }
        if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
          return '对方撤回了一条消息';
        }
        return `${this.conversation.lastMessage.fromAccount}撤回了一条消息`;
      }
      return this.conversation.lastMessage.messageForShow;
    },
    // currentOrder() {
    //   return this.diagnosisList.find(
    //     (i) => 'C2C' + i.patientId == this.conversation.conversationID
    //   );
    // },

    // diaName() {
    //   return this.currentOrder ? this.currentOrder.patientName : '';
    // },

    // diaOrderStatus() {
    //   return this.currentOrder ? this.currentOrder.orderStatus : '';
    // },
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
      currentUserProfile: (state) => state.user.currentUserProfile,

      diagnosisList: (state) => state.conversation.diagnosisList,
      custom: (state) => state.patient.custom,
      conversationList: (state) => {
        return state.conversation.conversationList;
      },

      diagnosisList: (state) => {
        return state.conversation.diagnosisList;
      },
      isBusy: (state) => {
        return state.video.isBusy;
      },
    }),
    ...mapGetters(['toAccount']),
  },

  mounted() {
    this.$bus.$on('drugPatients', (data) => {
      this.kaiyao = data;
    });

    this.$bus.$on('new-messsage-at-me', (event) => {
      if (
        event.data.conversationID === this.conversation.conversationID &&
        this.conversation.conversationID !==
          this.currentConversation.conversationID
      ) {
        this.hasMessageAtMe = true;
      }
    });
  },

  methods: {
    orderStatus(type) {
      let text = '';
      switch (type) {
        case 'PRE_CONSULTATION':
          text = '待预诊';
          break;
        case 'WAIT_FOR_SERVER':
          text = '待接诊';
          break;
        case 'SERVING':
          text = '接诊中';
          break;
        case 'COMPLETED':
          text = '已完成';
          break;
        case 'CANCELED':
          text = '已取消';
          break;
        default:
          text = '已完成';
          break;
      }
      return text;
    },
    async selectConversation() {
      // if (
      //   this.conversation.conversationID !==
      //   this.currentConversation.conversationID
      // ) {
      if (this.isBusy) {
        this.$message({
          message: '当前会话正在通话中！',
          type: 'warning',
        });
        return;
      }

      // this.$emit(clearNewTips, {
      //   consultationOrderNo:this.c
      // });
      //TODO  新消息提示
      // this.$store.commit('updateDiagnosisList', res.data.data);
      await this.$store.dispatch(
        'checkoutConversation',
        'C2C' + this.conversation.patientId
      );
      this.$store.commit('UPDATE_HANDEL_ITEM', {
        ...this.conversation,
        // ...this.currentOrder,
      });
      this.$store.dispatch('updateCustom');
      // }
    },

    deleteConversation: debounce(
      async function (id) {
        if (!this.diagnosisList.includes(id)) {
          this.$message({
            message: '请先填写诊断报告',
            type: 'warning',
          });
          return;
        }

        const res = await this.$http.post('/trtc/diagnose', {
          uuid: id, //诊断唯一标识
          noticeType: 1, // 正常结束: 1、异常结束: 2，诊断及处方：3
        });

        if (res.data.code != 0) {
          this.$message({
            message: '结束失败',
            type: 'warning',
          });
          return;
        }
        this.$message({
          message: '结束成功',
        });

        setTimeout(async () => {
          // conversationList;
          await this.$store.commit('resetCurrentConversation');
          let conversationList =
            this.$store.state.conversation.conversationList;

          console.log(conversationList, '删除后重置');
          // consol;
          if (conversationList && conversationList.length) {
            // 系统消息不渲染

            const item = conversationList.find(
              (i) => i['type'] != '@TIM#SYSTEM'
            );

            if (item) {
              this.$store.dispatch('checkoutConversation', item.conversationID);
            }
          } else {
            // $tim.deleteConversation(this.conversation.conversationID);
            this.$store.commit('updateCurrentConversation', {
              currentConversation: '',
            });
          }
        }, 500);
      },
      2000,
      { leading: true, trailing: false }
    ),
    showContextMenu() {
      this.popoverVisible = true;
    },
  },
  watch: {
    currentConversation(next) {
      if (next.conversationID === this.conversation.conversationID) {
        this.hasMessageAtMe = false;
      }
    },
    diagnosisList: {
      handler(newValue, oldValue) {
        const currentOrder = newValue.find(
          (i) => 'C2C' + i.patientId == this.conversation.conversationID
        );
        this.currentOrder = currentOrder;
        this.diaName = currentOrder ? currentOrder.patientName : '';

        this.diaOrderStatus = currentOrder ? currentOrder.orderStatus : '';
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation-item-container {
  box-sizing: border-box;
  position: relative;
  .text-ellipsis {
    span:nth-of-type(2) {
      color: #31b4b0;
      display: block;
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }
  .badge-item {
    position: absolute;
    right: 8px;
    top: 35px;
  }
  &.choose {
    border-left: 3px solid #31b4b0;
  }
  border-left: 3px solid #fff;
  color: #fff;
  .content {
    padding: 8px 16px;
    height: 54px;
    box-shadow: 0px -1px 0px 0px rgba(217, 217, 217, 0.5);
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    &:hover {
      .finish {
        color: #666666;
      }
    }
    .count-out {
      color: #31b4b0;
      font-size: 12px;
      line-height: 17px;
      margin-top: 3px;
      a {
        text-decoration: none;
        &.WAIT_FOR_SERVER {
          color: #e6a23c;
        }
      }
      .hasRead {
        color: #c1c1c1;
      }
    }
    .name {
      font-weight: 500;
      color: #4a4a4a;
      line-height: 20px;
    }
  }
  .row-2 {
    text-align: right;
    .date {
      font-size: 12px;
      font-family: SFProText-Regular, SFProText;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
    .finish {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff;
      line-height: 17px;
      margin-top: 5px;
      //   color: #666666;
    }
  }
}
</style>

<style lang="stylus" scoped>
// .conversation-item-container
//   padding 15px 20px
//   cursor pointer
//   position relative
//   overflow hidden
//   transition .2s
//   // &:first-child
//   //   padding-top 30px
//   &:hover
//     background-color $background
//     .close-btn
//       right 3px
//   .close-btn
//     position absolute
//     right -20px
//     top 3px
//     color $font-dark
//     transition: all .2s ease;
//     &:hover
//       color $danger
//   .warp
//     display flex
//   .avatar
//     width 40px
//     height 40px
//     margin-right 10px
//     border-radius 50%
//     flex-shrink 0
//   .content
//     flex 1
//     height 40px
//     overflow hidden
//     .row-1
//       display flex
//       line-height 21px
//       .name
//         color $font-light
//         flex 1
//         min-width 0px
//       .unread-count
//         padding-left 10px
//         flex-shrink 0
//         color $font-dark
//         font-size 12px
//         .badge
//           vertical-align bottom
//           background-color $danger
//           border-radius 10px
//           color #FFF
//           display inline-block
//           font-size 12px
//           height 18px
//           max-width 40px
//           line-height 18px
//           padding 0 6px
//           text-align center
//           white-space nowrap
//     .row-2
//       display flex
//       font-size 12px
//       padding-top 3px
//       .summary
//         flex 1
//         overflow hidden
//         min-width 0px
//         color: $secondary
//         .remind
//           color $danger
//       .date
//         padding-left 10px
//         flex-shrink 0
//         text-align right
//         color $font-dark
// .choose {
//   background-color: $background;
// }
// .context-menu-button {
//   padding: 10px
//   border: 2px solid $primary;
//   border-radius: 8px;
// }
</style>
