<template>
  <div class="im-wraper">
    <div class="list-container">
      <conversation-list />
    </div>
    <div class="conversation-out">
      <current-conversation />
    </div>
    <image-previewer />
    <Calling ref="callLayer" class="chat-wrapper" />
  </div>
</template>

<script>
import ConversationList from './conversation/conversation-list';
import CurrentConversation from './conversation/current-conversation';
import ImagePreviewer from './message/image-previewer.vue';
import Calling from '../trtc-calling/calling-index';
export default {
  components: {
    ConversationList,
    CurrentConversation,
    ImagePreviewer,
    Calling,
  },
  data() {
    return {
      isShowScrollButtomTips: false,
      preScrollHeight: 0,
      messageContent: '',
    };
  },
  async mounted() {
    // this.loginIm();
  },

  methods: {
    // async loginIm() {
    //   await initIm(1400518416);
    //   await $tim.login({
    //     userID: '3211', //用户ID
    //     userSig:
    //       'eJwtzF0LgjAUxvHvsuuQMz2uTehmBt1EIIWFd4GzDqKs*ZIVffeGefn8Hvh-2Gl-DEbjWMLCANhq3lSatqeKZo5CzhfvyvpqLZUs4QgQc4lc-J*eGuNVhApjULComSw57wJQAiwNuvmojsb0fH-rdofFY42dEqorLkM*DTLLt-VBOk3pq9HumW3Y9wcY7S-*', //用户签名
    //   });
    //   setTimeout(async () => {
    //     const promise = await $tim.getConversationList();

    //     const { data = {} } = promise;

    //     if (data.conversationList && data.conversationList.length) {
    //       // 系统消息不渲染

    //       const item = data.conversationList.find(
    //         (i) => i['type'] != '@TIM#SYSTEM'
    //       );
    //       // ['conversationID'];
    //       if (item) {
    //         this.$store.dispatch('checkoutConversation', item.conversationID);
    //       }
    //     }
    //   }, 500);
    // },
    join() {
      // $tim.getConversationList();
      // this.$bus.$emit('drugPatients', [
      //     // '0001',
      //     // 'gh123',
      //     'db5ee492-70b3-42f3-a7ad-c84f711dd764',
      //     // '@TGS#3EEY22FHU',
      // ]);
      // $tim.joinGroup({
      //   groupID: 'gh1234', //im聊天室
      //   applyMessage: '',
      // });
      // $tim.quitGroup('gh1234');
    },
  },
};
</script>

<style lang="scss">
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.im-wraper {
  height: 85vh;
  width: 100%;
  display: flex;
}
.list-container {
  width: 29%;
  background: #fff;
  height: 80vh;
}

.conversation-out {
  width: 71%;
  display: flex;
  flex-direction: column;
}

.more {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 12px;
}

.no-more {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #a5b5c1;
  font-size: 12px;
  padding: 10px 10px;
}
</style>
