export default {
  TUWEN: {
    title: '图文问诊',
    consultationTypes: '1',
  },
  SHIPIN: {
    title: '视频问诊',
    consultationTypes: '4',
  },
  JIANKANG: {
    title: '健康咨询',
    consultationTypes: '108',
  },
  ZHUANBING: {
    title: '专病服务',
    consultationTypes: '120',
  },
  SHISHI: {
    title: '实时视频问诊',
    consultationTypes: '401',
  },
};
