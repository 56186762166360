<template>
  <div class="current-conversation-wrapper">
    <div class="current-conversation" @scroll="onScroll">
      <!--  -->
      <div class="header" v-if="showCurrentConversation">
        <div class="name" @click="showMsg">
          {{ custom.resource.patientName }}
        </div>
      </div>
      <div class="content">
        <div
          class="message-list"
          ref="message-list"
          @scroll="this.onScroll"
          v-if="showCurrentConversation"
        >
          <div class="more" v-if="!isCompleted">
            <el-button
              type="text"
              @click="
                $store.dispatch(
                  'getMessageList',
                  currentConversation.conversationID
                )
              "
              >查看更多</el-button
            >
          </div>
          <div class="no-more" v-else>没有更多了</div>
          <message-item
            v-for="message in currentMessageList"
            :key="message.ID"
            :message="message"
            :avatars="avatars"
          />
        </div>
        <div
          v-show="isShowScrollButtomTips && showCurrentConversation"
          class="newMessageTips"
          @click="scrollMessageListToButtom"
        >
          回到最新位置
        </div>

        <div class="has-over" v-if="diagnosisList.includes(groupID)">
          您可以根据问诊情况“结束对话”,结束前患者仍可与您交流。
        </div>

        <div v-if="!showCurrentConversation" class="no-connent">
          <img src="../assets/no-data.png" alt="" srcset="" />
          <p>暂无对话</p>
        </div>
      </div>

      <div class="footer">
        <message-send-box ref="messageSendBox" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MessageSendBox from '../message/message-send-box';
import MessageItem from '../message/message-item';
// import ConversationProfile from './conversation-profile.vue';

// import MemberProfileCard from '../group/member-profile-card';

export default {
  name: 'CurrentConversation',
  components: {
    MessageSendBox,
    MessageItem,
    // ConversationProfile,
    // MemberProfileCard,
  },
  data() {
    return {
      isShowScrollButtomTips: false,
      preScrollHeight: 0,
      showConversationProfile: false,
      timeout: '',

      avatars: {},
    };
  },

  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
      currentUnreadCount: (state) =>
        state.conversation.currentConversation.unreadCount,

      // (payload.data && payload.data.includes('call_type'))
      currentMessageList(state) {
        return state.conversation.currentMessageList.filter(
          (item) => {
            const { cloudCustomData, payload } = item;

            if (
              cloudCustomData &&
              JSON.parse(cloudCustomData).consultationOrderNo ==
                this.custom.consultationOrderNo &&
              (payload.extension
                ? JSON.parse(payload.extension).type != 'ConsultationServing'
                : true)
            ) {
              return true;
            }
            // console.log(payload, 'cloudCustomData');

            // if (
            //   payload &&
            //   (payload.text.includes('接听') || payload.text.includes('通话'))
            // ) {
            //   return true;
            // }
            return false;
          }
          // item.cloudCustomData &&
          // JSON.parse(item.cloudCustomData).consultationOrderNo ==
          //   this.custom.consultationOrderNo &&
          // (item.payload.extension
          //   ? JSON.parse(item.payload.extension).type != 'ConsultationServing'
          //   : true)
        );
      },
      currentMessageList1: (state) => state.conversation.currentMessageList,

      isCompleted: (state) => state.conversation.isCompleted,
      diagnosisList: (state) => state.conversation.diagnosisList,

      custom: (state) => state.patient.custom,
    }),
    ...mapGetters(['toAccount', 'hidden']),
    // 是否显示当前会话组件
    showCurrentConversation() {
      return !!this.currentConversation.conversationID;
    },
    groupID() {
      if (
        this.currentConversation.groupProfile &&
        this.currentConversation.groupProfile.groupID
      ) {
        return this.currentConversation.groupProfile.groupID;
      }

      return null;
    },
    name() {
      if (this.currentConversation.type === 'C2C') {
        return this.currentConversation.userProfile.nick || this.toAccount;
      } else if (this.currentConversation.type === 'GROUP') {
        return this.currentConversation.groupProfile.name || this.toAccount;
      } else if (this.currentConversation.conversationID === '@TIM#SYSTEM') {
        return '系统通知';
      }
      return this.toAccount;
    },
    // showMessageSendBox() {
    //     return this.currentConversation.type !== this.TIM.TYPES.CONV_SYSTEM;
    // },
  },
  mounted() {
    this.$bus.$on('image-loaded', this.onImageLoaded);
    this.$bus.$on('scroll-bottom', this.scrollMessageListToButtom);

    this.$bus.$on('avatarChange', (data) => {
      // console.log(data, '头像变化');

      this.avatars = data;
    });
    if (this.currentConversation.conversationID === '@TIM#SYSTEM') {
      return false;
    }
  },
  updated() {
    this.keepMessageListOnButtom();
    // 1. 系统会话隐藏右侧资料组件
    // 2. 没有当前会话时，隐藏右侧资料组件。
    //    背景：退出群组/删除会话时，会出现一处空白区域
    if (
      this.currentConversation.conversationID === '@TIM#SYSTEM' ||
      typeof this.currentConversation.conversationID === 'undefined'
    ) {
      this.showConversationProfile = false;
    }
  },
  watch: {
    currentUnreadCount(next) {
      if (!this.hidden && next > 0) {
        $tim.setMessageRead({
          conversationID: this.currentConversation.conversationID,
        });
      }
    },
    hidden(next) {
      if (!next && this.currentUnreadCount > 0) {
        $tim.setMessageRead({
          conversationID: this.currentConversation.conversationID,
        });
      }
    },
  },
  methods: {
    showMsg() {
      console.log(this.currentMessageList1, this.currentConversation);
    },
    onScroll({ target: { scrollTop } }) {
      let messageListNode = this.$refs['message-list'];
      if (!messageListNode) {
        return;
      }
      if (
        this.preScrollHeight - messageListNode.clientHeight - scrollTop <
        20
      ) {
        this.isShowScrollButtomTips = false;
      }
    },
    // 如果滚到底部就保持在底部，否则提示是否要滚到底部
    keepMessageListOnButtom() {
      let messageListNode = this.$refs['message-list'];
      if (!messageListNode) {
        return;
      }
      // 距离底部20px内强制滚到底部,否则提示有新消息
      if (
        this.preScrollHeight -
          messageListNode.clientHeight -
          messageListNode.scrollTop <
        20
      ) {
        this.$nextTick(() => {
          messageListNode.scrollTop = messageListNode.scrollHeight;
        });
        this.isShowScrollButtomTips = false;
      } else {
        this.isShowScrollButtomTips = true;
      }
      this.preScrollHeight = messageListNode.scrollHeight;
    },
    // 直接滚到底部
    scrollMessageListToButtom() {
      this.$nextTick(() => {
        let messageListNode = this.$refs['message-list'];
        if (!messageListNode) {
          return;
        }
        messageListNode.scrollTop = messageListNode.scrollHeight;
        this.preScrollHeight = messageListNode.scrollHeight;
        this.isShowScrollButtomTips = false;
      });
    },
    showMore() {
      this.showConversationProfile = !this.showConversationProfile;
    },
    onImageLoaded() {
      this.keepMessageListOnButtom();
    },
  },
};
</script>

<style lang="stylus" scoped>
/* 当前会话的骨架屏 */

.no-connent
    padding-top 145px
    img
        display block
        width 168px
        height 134px
        margin 0 auto

    p
        font-size 13px
        font-family PingFangSC-Regular, PingFang SC
        font-weight 400
        color #9B9B9B
        line-height 17px;
        text-align center



.current-conversation-wrapper
  height $height
  background-color $background-light
  color $base
  display flex
  .current-conversation
    display: flex;
    flex-direction: column;
    width: 100%;
    height: $height;
  .profile
    height: $height;
    overflow-y: scroll;
    width 220px
    border-left 1px solid $border-base
    flex-shrink 0
  .more
    display: flex;
    justify-content: center;
    font-size: 12px;
  .no-more
    display: flex;
    justify-content: center;
    color: $secondary;
    font-size: 12px;
    padding: 10px 10px;

.header
  border-bottom 1px solid $border-base
  height 50px
  position relative
  .name
    padding 0 20px
    color $base
    font-size 18px
    font-weight bold
    line-height 50px
    text-shadow $font-dark 0 0 0.1em
  .btn-more-info
    position absolute
    top 10px
    right -15px
    border-radius 50%
    width 30px
    height 30px
    cursor pointer
    &::before
      position absolute
      right 0
      z-index 0
      content ""
      width: 15px
      height: 30px
      border: 1px solid $border-base
      border-radius: 0 100% 100% 0/50%
      border-left: none
      background-color $background-light
    &::after
      content ""
      width: 8px;
      height: 8px;
      transition: transform 0.8s;
      border-top: 2px solid $secondary;
      border-right: 2px solid $secondary;
      float:right;
      position:relative;
      top: 11px;
      right: 8px;
      transform:rotate(45deg)
    &.left-arrow
      transform rotate(180deg)
      &::before
        background-color $white
    &:hover
      &::after
        border-color $light-primary
.content
  display: flex;
  flex 1
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  .has-over
    margin :20px auto;
    text-align:center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  .message-list
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    padding: 0 20px;
  .newMessageTips
    position: absolute
    cursor: pointer;
    padding: 5px;
    width: 120px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 5px;
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
    border: $border-light 1px solid;
    background-color: $white;
    color: $primary;
.footer
//   border-top: 1px solid $border-base;
  height 250px
.show-more {
  text-align: right;
  padding: 10px 20px 0 0;
}
</style>
