<template>
  <div class="list-container">
    <!-- <p v-show="false">{{ totalUnreadCount }}</p> -->
    <!-- <div class="header-bar">
      <el-button
        type="primary"
        class="ref-btn"
        icon="el-icon-refresh"
        @click="handleRefresh"
        :loading="isLoading"
        >刷新列表</el-button
      >
    </div> -->
    <!-- <div class="scroll-container">
      <conversation-item
        :conversation="item"
        v-for="item in conversationListDia"
        :key="item.conversationID"
      />
    </div> -->
    <div class="scroll-container">
      <conversation-item
        :conversation="item"
        v-for="item in conversationListDia"
        :key="item.consultationOrderNo"
        @clearNewTips="clearNewTips"
      />
    </div>
  </div>
</template>

<script>
import ConversationItem from './conversation-item';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'ConversationList',
  components: { ConversationItem },
  data() {
    return {
      showDialog: false,
      userID: '',
      isCheckouting: false, // 是否正在切换会话
      timeout: null,
      isLoading: false,
      // conversationList: [],
      conversationListDia: [],
    };
  },
  computed: {
    ...mapGetters(['totalUnreadCount']),
    ...mapState({
      // conversationList: (state) => {
      //   if (!this.diagnosisList.length) {
      //     return [];
      //   }
      //   return state.conversation.conversationList.filter((i) =>
      //     diagnosisListOrderS.includes(i.lastMessage.cloudCustomData)
      //   );
      conversationList: (state) => {
        // return state.conversation.conversationList.filter(
        //   (i) => i.lastMessage.cloudCustomData
        // );

        return state.conversation.conversationList;
      },
      custom: (state) => state.patient.custom,
      //TODO 会话列表通过 watch 跟诊断作比较
      diagnosisList: (state) => {
        return state.conversation.diagnosisList;
      },

      currentConversation: (state) => state.conversation.currentConversation,
    }),
  },

  watch: {
    //TODO 处理下同一个会话 不同问诊类型
    diagnosisList: {
      handler(newValue, oldValue) {
        if (!newValue.length) {
          this.conversationListDia = [];
          return;
        }
        let lastMessageNo = '';

        let underReadArr = [];
        if (this.conversationList.length) {
          //1 先获取有未读消息的会话列表
          underReadArr = this.conversationList.map((i) => {
            if (i.unreadCount) {
              const lastMsg = i.lastMessage;
              if (lastMsg.cloudCustomData) {
                return JSON.parse(lastMsg.cloudCustomData).consultationOrderNo;
              }
            }
          });
          // 2 获取最新会话消息
          const { lastMessage } = this.conversationList[0];
          const { cloudCustomData } = lastMessage;
          lastMessageNo = JSON.parse(cloudCustomData).consultationOrderNo;
        }

        const { consultationOrderNo } = this.custom;

        this.conversationListDia = newValue.map((i) => {
          let unreadCount = 0;

          if (underReadArr.includes(i.consultationOrderNo)) {
            unreadCount = 1;
          }
          // if(){}

          return {
            ...i,
            unreadCount,
            // i.consultationOrderNo == lastMessageNo &&
            // i.consultationOrderNo != consultationOrderNo
            //   ? 1
            //   : 0,
          };
        });

        // unreadCount

        // console.log(newValue, this.conversationList, 'newValue');

        // const diagnosisListOrderS = newValue.map((i) => i.consultationOrderNo);
        // console.log(diagnosisListOrderS, 'diagnosisListOrderS');
        // this.conversationListDia = this.conversationList.filter((i) => {
        //   const { cloudCustomData, payload } = i.lastMessage;
        //   console.log(
        //     JSON.parse(cloudCustomData).consultationOrderNo,
        //     'cloudCustomData'
        //   );
        //   return (
        //     cloudCustomData &&
        //     diagnosisListOrderS.includes(
        //       JSON.parse(cloudCustomData).consultationOrderNo
        //     )
        //   );

        //   //    return (
        //   //   (cloudCustomData &&
        //   //     diagnosisListOrderS.includes(
        //   //       JSON.parse(cloudCustomData).consultationOrderNo
        //   //     )) ||
        //   //   (payload.data && payload.data.includes('call_type'))
        //   // );
        // });
      },
      deep: true,
      immediate: true,
    },

    custom: {
      handler(newValue, oldValue) {
        const { consultationOrderNo } = newValue;
        this.conversationListDia.forEach((i) => {
          if (i.consultationOrderNo == consultationOrderNo) {
            i.unreadCount = 0;
          }
        });
      },
      deep: true,
    },
  },
  mounted() {},
  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    clearNewTips() {},
    handleRefresh() {
      console.log(this.conversationList);
      // let tim = window.$tim || '';
      // if (tim) {
      //   this.isLoading = true;
      //   this.refreshConversation()();
      // } else {
      //   this.$message({
      //     message: '暂无登录信息',
      //   });
      // }
    },
    refreshConversation() {
      let that = this;
      return function () {
        if (!that.timeout) {
          that.timeout = setTimeout(() => {
            that.isLoading = false;
            that.timeout = null;
            $tim.getConversationList().then(() => {
              that.$store.commit('showMessage', {
                message: '刷新成功',
                type: 'success',
              });
            });
          }, 1000);
        }
      };
    },
    // handleAddButtonClick() {
    //   this.showDialog = true;
    // },
    // handleConfirm() {
    //   if (this.userID !== '@TIM#SYSTEM') {
    //     this.$store
    //       .dispatch('checkoutConversation', `C2C${this.userID}`)
    //       .then(() => {
    //         this.showDialog = false;
    //       })
    //       .catch(() => {
    //         this.$store.commit('showMessage', {
    //           message: '没有找到该用户',
    //           type: 'warning',
    //         });
    //       });
    //   } else {
    //     this.$store.commit('showMessage', {
    //       message: '没有找到该用户',
    //       type: 'warning',
    //     });
    //   }
    //   this.userID = '';
    // },
    // handleKeydown(event) {
    //     if (
    //         (event.keyCode !== 38 && event.keyCode !== 40) ||
    //         this.isCheckouting
    //     ) {
    //         return;
    //     }
    //     const currentIndex = this.conversationList.findIndex(
    //         (item) =>
    //             item.conversationID ===
    //             this.currentConversation.conversationID
    //     );
    //     if (event.keyCode === 38 && currentIndex - 1 >= 0) {
    //         this.checkoutPrev(currentIndex);
    //     }
    //     if (
    //         event.keyCode === 40 &&
    //         currentIndex + 1 < this.conversationList.length
    //     ) {
    //         this.checkoutNext(currentIndex);
    //     }
    // },
    checkoutPrev(currentIndex) {
      this.isCheckouting = true;
      this.$store
        .dispatch(
          'checkoutConversation',
          this.conversationList[currentIndex - 1].conversationID
        )
        .then(() => {
          this.isCheckouting = false;
        })
        .catch(() => {
          this.isCheckouting = false;
        });
    },
    checkoutNext(currentIndex) {
      this.isCheckouting = true;
      this.$store
        .dispatch(
          'checkoutConversation',
          this.conversationList[currentIndex + 1].conversationID
        )
        .then(() => {
          this.isCheckouting = false;
        })
        .catch(() => {
          this.isCheckouting = false;
        });
    },
  },
};
</script>

<style lang="stylus" scoped>

.ref-btn
  display block
  width 100%

.list-container
  height 100%
  width 100%
  display flex
  flex-direction column // -reverse
//   .header-bar
//     flex-shrink 0
//     height 50px
//     border-bottom 1px solid $background-deep-dark
//     padding 10px 10px 10px 20px
//     button
//       float right
//       display: inline-block;
//       cursor: pointer;
//       background $background-deep-dark
//       border: none
//       color: $font-dark;
//       box-sizing: border-box;
//       transition: .3s;
//       -moz-user-select: none;
//       -webkit-user-select: none;
//       -ms-user-select: none;
//       margin: 0 10px 0 0
//       padding 0
//       width 30px
//       height 30px
//       line-height 34px
//       font-size: 24px;
//       text-align: center;
//       white-space: nowrap;
//       border-radius: 50%
//       outline 0
//       &:hover
//         // background $light-primary
//         // color $white
//         transform: rotate(360deg);
//         color $light-primary
//   .scroll-container
//     overflow-y scroll
//     flex 1
.bottom-circle-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.refresh {
  bottom: 70px;
}
</style>
